import { Link } from "../../../components/Link/Link";
import React, { useContext, useMemo } from "react";
import { PageContent, PageSection, Heading1 } from "../Wordpress/Wordpress";
import { getTitle } from "../../../utils/doctor";
import { AllDoctorsPage as PageDefinition, DoctorProfilePage, GetPageContext } from "../../../pages";
import { pageContext } from "../../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../../components/SEO/SEOReactHelmet";

type AllDoctorsPageContext = GetPageContext<typeof PageDefinition>;

const AllDoctorsPage: React.FunctionComponent = () => {
  const {
    extraContext: { doctors },
  } = useContext<AllDoctorsPageContext>(pageContext);
  const sortedDoctors = useMemo(
    () =>
      doctors
        .map((doctor) => [doctor.slug, getTitle(doctor)] as const)
        .sort((a, b) => a[0].localeCompare(b[0])),
    [doctors]
  );

  return (
    <>
      <SEOReactHelmet title="All low carb doctors — Diet Doctor" />
      <PageContent>
        <Heading1>All low carb doctors</Heading1>
        <PageSection>
          {sortedDoctors.map(([slug, title]) => (
            <li key={slug}>
              <Link to={DoctorProfilePage} params={{ slug }}>
                {title}
              </Link>
            </li>
          ))}
        </PageSection>
      </PageContent>
    </>
  );
};

// ts-prune-ignore-next
export default AllDoctorsPage;
